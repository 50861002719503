<template>
  <div
    id="chart"
    style="width: 100%"
  >
    <h2 class="center">{{$t("Last Three Weeks Devices Installation")}}</h2>
    <VueApexCharts
      v-if="Object.keys(chartOptions).length > 0"
      type="line"
      height="350"
      width="100%"
      v-bind:options="chartOptions"
      v-bind:series="graphData"
    ></VueApexCharts>
  </div>
</template>
<script>
import VueApexCharts from 'vue-apexcharts';

export default {
  name: 'WeeklyCommitionedXY',
  components: { VueApexCharts },
  props: ['lastWeek', 'twoWeeks', 'threeWeeks', 'weekDays', 'contractors'],
  data() {
    return {
      chartOptions: {}, 
      graphData : [],
      device_types : {fixture: 'Tondo Device', cabinet: 'Cabinet Monitor', camera: 'Camera'}
    };
  }, 
  methods: {
    generateGraphData(){
      [...this.lastWeek.entries()].forEach(([contractor, data]) => {
        Object.entries(data).forEach(([key, value]) => {
          this.graphData.push({name: `${this.contractors[contractor].contractor_name} - ${this.device_types[key]} last week`, data: Object.values(value).filter((v) => !isNaN(v)).reverse().slice(1)});
        });
      });
      [...this.twoWeeks.entries()].forEach(([contractor, data]) => {
        Object.entries(data).forEach(([key, value]) => {
          this.graphData.push({name: `${this.contractors[contractor].contractor_name} - ${this.device_types[key]} two weeks ago`, data: Object.values(value).filter((v) => !isNaN(v)).reverse().slice(1)});
        });
      });
      [...this.threeWeeks.entries()].forEach(([contractor, data]) => {
        Object.entries(data).forEach(([key, value]) => {
          this.graphData.push({name: `${this.contractors[contractor].contractor_name} - ${this.device_types[key]} three weeks ago`, data: Object.values(value).filter((v) => !isNaN(v)).reverse().slice(1)});
        });
      });
      this.chartOptions = {
        grid: {
          show: true,
          borderColor: 'rgba(0, 0, 0, .2)',
          strokeDashArray: 3,
          xaxis: {
            lines: {
              show: true
            }
          },
          yaxis: {
            lines: {
              show: true
            }
          }
        },
        dataLabels: {
          enabled: false
        },
        chart: {
          toolbar: {
            show: true
          }
        },
        stroke: {
          curve: 'straight',
          width: 2
        },
        colors: [
          '#5C6BC0',
          '#1A237E',
          '#1DE9B6',
          '#7CB342',
          '#33691E',
          '#76FF03',
          '#EC407A',
          '#AD1457',
          '#FF80AB'
        ],
        xaxis: {
          categories: this.weekDays,
          labels: {
            style: {
              cssClass: 'grey--text lighten-2--text fill-color'
            }
          }
        },
        yaxis: {
          labels: {
            style: {
              cssClass: 'grey--text lighten-2--text fill-color'
            }
          }
        },
        markers: {
          size: 3
        },
        tooltip: {
          x: {
            format: 'dd/MM/yy HH:mm'
          },
          theme: 'dark'
        },
        legend: {
          show: false
        }
      };
    }
  },
  created() {
    if (!this.noData) this.generateGraphData();
  }
};
</script>
<style scoped>
</style>