













import { Reports, User, Global, Props } from '@/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import PropUtils from '@/modules/PropUtils';
import { Prop } from 'vue-property-decorator';
import moment from 'moment-timezone';
import Utils from '@/modules/Utils';
import WeeklyCommissionedTable from '@/pages/reports/components/WeeklyCommissionedTable.vue';
import WeeklyCommissionedXY from '@/pages/reports/components/WeeklyCommissionedXY.vue';
import cloneDeep from 'lodash/cloneDeep';

@Component({ 
    components: {
        WeeklyCommissionedTable,
        WeeklyCommissionedXY
    } 
})

export default class WeeklyCommissioned extends Vue {
    @Prop() weeklyCommissionedDialog;
    @Prop() handleDialogClicked;

    @Global.State('lang') lang;
    @Global.State('timezone') projectTimezone;
    @User.State('project') project;
    @Reports.Getter('commissioned_map') commissioned_map;
    @Props.State('list') projectProperties;

    contractors = null;
    commissioners = new Map();
    device_types = {fixture: 'Tondo Device', cabinet: 'Cabinet Monitor'};
    loading = true;
    noData = false;
    today_timezone = null;
    before_week_timezone = null;
    before_two_weeks_timezone = null;
    before_three_weeks_timezone = null;
    weekDays = [];
    weekly_by_contractor_last_week = new Map();
    weekly_by_contractor_two_weeks_ago = new Map();
    weekly_by_contractor_three_weeks_ago = new Map();
    commissioned_last_week = [];
    commissioned_last_two_weeks = [];
    commissioned_last_three_weeks = [];
    START_TIME = '';
    END_TIME = '';
    MAX_DAYS = 8;
    week_dates = [];
    last_week_dates = [];
    two_weeks_ago_dates = [];
    types_by_date = {};
    last_week_types_by_date = {};
    two_weeks_ago_types_by_date = {};
    message = '';
    default_contractor = 'Unknown';
    default_commissioner = 'unknown';

    mounted() {
        this.loading = true;
        this.noData = false;

        this.setContractorsProperty();
        this.setThresholdProperty();

        if (this.contractors && Object.keys(this.contractors).length){
            this.setDatesList();
            this.generateTypesByDate();
            this.initializeCommissioners();
            this.initializeContractorDeviceTypes();
            this.generateDataByContractor();
        }else{
            this.noData = true;
            this.message = 'No Data Available';
        }

        this.loading = false;
    }

    setContractorsProperty(){
        const contractors_property = PropUtils.getProperty(this.projectProperties, 'project.contractors');
        this.contractors = contractors_property ? contractors_property.value : null;
        if (!this.contractors || !Object.keys(this.contractors).length){
            this.contractors = {};
        }
        this.contractors[this.default_contractor] = {
            contractor_name: this.default_contractor,
            area: [this.default_contractor], 
            email: this.default_contractor,
            phone: '',
            commissioners: [this.default_commissioner]
        };
    }

    setThresholdProperty(){
        const default_time = '06:00';
        const thresholdProperty = PropUtils.getProperty(this.projectProperties, 'meta.events_threshold');
        if (thresholdProperty){
            this.START_TIME = thresholdProperty.value.commissionedStart;
            this.END_TIME = thresholdProperty.value.commissionedEnd;
        }else{
            this.START_TIME = default_time;
            this.END_TIME = default_time;
        }
    }

    setDatesList(){
        this.today_timezone = moment.tz(this.projectTimezone).format('YYYY-MM-DD');
        this.before_week_timezone = moment.tz(this.projectTimezone).subtract(7, 'days').format('YYYY-MM-DD');
        this.before_two_weeks_timezone = moment.tz(this.projectTimezone).subtract(14, 'days').format('YYYY-MM-DD');
        this.before_three_weeks_timezone = moment.tz(this.projectTimezone).subtract(21, 'days').format('YYYY-MM-DD');

        let index = 1;
        while (index < this.MAX_DAYS){
            const day = moment.tz(this.projectTimezone).subtract(index, 'days').format('YYYY-MM-DD');
            this.week_dates.push(day);
            this.weekDays.push(new Date(day).toString().substring(0, 3));
            const lastWeekDay = moment.tz(this.projectTimezone).subtract(index + 7, 'days').format('YYYY-MM-DD');
            this.last_week_dates.push(lastWeekDay);
            const twoWeeksAgoDay = moment.tz(this.projectTimezone).subtract(index + 14, 'days').format('YYYY-MM-DD');
            this.two_weeks_ago_dates.push(twoWeeksAgoDay);
            ++index;
        }
        this.weekDays = this.weekDays.reverse();
    }

    generateTypesByDate(){
        Object.keys(this.device_types).forEach((type) => {
            this.types_by_date[type] = {};
            this.week_dates.forEach((date) => {
                this.types_by_date[type] = {...this.types_by_date[type], [date]: 0};
            });
            this.types_by_date[type] = {...this.types_by_date[type], total: 0};
            
            this.last_week_types_by_date[type] = {};
            this.last_week_dates.forEach((date) => {
                this.last_week_types_by_date[type] = {...this.last_week_types_by_date[type], [date]: 0};
            });
            this.last_week_types_by_date[type] = {...this.last_week_types_by_date[type], total: 0};

            this.two_weeks_ago_types_by_date[type] = {};
            this.two_weeks_ago_dates.forEach((date) => {
                this.two_weeks_ago_types_by_date[type] = {...this.two_weeks_ago_types_by_date[type], [date]: 0};
            });
            this.two_weeks_ago_types_by_date[type] = {...this.two_weeks_ago_types_by_date[type], total: 0};
        });
    }
    
    initializeCommissioners(){
        Object.values(this.contractors).forEach((contractor) => {
            contractor['commissioners'].forEach((commissioner) => {
                this.commissioners.set(commissioner.toLowerCase(), contractor['email']);
            });
        });
    }

    initializeContractorDeviceTypes(){
        Object.values(this.contractors).forEach((contractor) => {
            this.weekly_by_contractor_last_week.set(contractor['email'], cloneDeep(this.types_by_date));
            this.weekly_by_contractor_two_weeks_ago.set(contractor['email'], cloneDeep(this.last_week_types_by_date));
            this.weekly_by_contractor_three_weeks_ago.set(contractor['email'], cloneDeep(this.two_weeks_ago_types_by_date));
        });
    }
    generateDataByContractor(){
        this.getCommissionedLastWeek();
        this.commissioned_last_week.length + this.commissioned_last_two_weeks.length + this.commissioned_last_three_weeks.length
            ? this.dividDevicesByContractor()
            : this.noData = true;
        if (this.noData) this.message = 'No devices were installed this week';
    }

    getCommissionedLastWeek(){
        [...this.commissioned_map.values()].forEach((device) => {
            const commission_date = Utils.convertCommissionDate(device['meta.commission'].date, 'YYYY-MM-DD');

            if (commission_date && Utils.isDateBetween(commission_date, this.before_week_timezone, this.today_timezone, '[]')){
                this.commissioned_last_week.push(device.id);
            }else if (commission_date && Utils.isDateBetween(commission_date, this.before_two_weeks_timezone, this.before_week_timezone, '[]')){
                this.commissioned_last_two_weeks.push(device.id);
            }else if (commission_date && Utils.isDateBetween(commission_date, this.before_three_weeks_timezone, this.before_two_weeks_timezone, '[]')){
                this.commissioned_last_three_weeks.push(device.id);
            }
        });
    }

    dividDevicesByContractor(){
        this.commissioned_last_week.forEach((device_id) => {
            const device = this.commissioned_map.get(device_id);
            const contractor = this.commissioners.get(device['meta.commission'].commissioner.toLowerCase()) || this.commissioners.get(this.default_commissioner);
            const type = Utils.getDeviceType(device.class_name);
            if (contractor && this.weekly_by_contractor_last_week.get(contractor)[type]){
                const commission_datetime = Utils.convertCommissionDate(device['meta.commission'].date, 'YYYY-MM-DD HH:mm:ss');
                if (commission_datetime){
                    const commission_date = this.getCommissionDate(commission_datetime);
                    if (commission_date && this.week_dates.includes(commission_date)){
                        ++this.weekly_by_contractor_last_week.get(contractor)[type][commission_date];
                        ++this.weekly_by_contractor_last_week.get(contractor)[type].total;
                    }
                }
            }
        });
        if (!Object.values(this.weekly_by_contractor_last_week.get(this.default_contractor)).some((type) => type['total'])) this.weekly_by_contractor_last_week.delete(this.default_contractor);

        this.commissioned_last_two_weeks.forEach((device_id) => {
            const device = this.commissioned_map.get(device_id);
            const contractor = this.commissioners.get(device['meta.commission'].commissioner.toLowerCase()) || this.commissioners.get(this.default_commissioner);
            const type = Utils.getDeviceType(device.class_name);
            if (contractor && this.weekly_by_contractor_two_weeks_ago.get(contractor)[type]){
                const commission_datetime = Utils.convertCommissionDate(device['meta.commission'].date, 'YYYY-MM-DD HH:mm:ss');
                if (commission_datetime){
                    const commission_date = this.getCommissionDate(commission_datetime);
                    if (commission_date){
                        ++this.weekly_by_contractor_two_weeks_ago.get(contractor)[type][commission_date];
                        ++this.weekly_by_contractor_two_weeks_ago.get(contractor)[type].total;
                    }
                }
            }
        });
        if (!Object.values(this.weekly_by_contractor_two_weeks_ago.get(this.default_contractor)).some((type) => type['total'])) this.weekly_by_contractor_two_weeks_ago.delete(this.default_contractor);
        
        this.commissioned_last_three_weeks.forEach((device_id) => {
            const device = this.commissioned_map.get(device_id);
            const contractor = this.commissioners.get(device['meta.commission'].commissioner.toLowerCase()) || this.commissioners.get(this.default_commissioner);
            const type = Utils.getDeviceType(device.class_name);
            if (contractor && this.weekly_by_contractor_three_weeks_ago.get(contractor)[type]){
                const commission_datetime = Utils.convertCommissionDate(device['meta.commission'].date, 'YYYY-MM-DD HH:mm:ss');
                if (commission_datetime){
                    const commission_date = this.getCommissionDate(commission_datetime);
                    if (commission_date){
                        ++this.weekly_by_contractor_three_weeks_ago.get(contractor)[type][commission_date];
                        ++this.weekly_by_contractor_three_weeks_ago.get(contractor)[type].total;
                    }
                }
            }
        });
        if (!Object.values(this.weekly_by_contractor_three_weeks_ago.get(this.default_contractor)).some((type) => type['total'])) this.weekly_by_contractor_three_weeks_ago.delete(this.default_contractor);
        if (!this.weekly_by_contractor_last_week.size && !this.weekly_by_contractor_two_weeks_ago.size && !this.weekly_by_contractor_three_weeks_ago.size) this.noData = true;
    }

    getCommissionDate(commission_datetime){
        const today_with_end_time = moment(`${this.today_timezone} ${this.END_TIME}`).valueOf();
        const commission_timestamp = moment(commission_datetime).valueOf();
        const commission_date = moment(commission_datetime).format('YYYY-MM-DD');
        const commission_with_start_time = moment(`${commission_date} ${this.START_TIME}`).valueOf();
        const commission_with_end_time = moment(`${commission_date} ${this.END_TIME}`).valueOf();

        if (Utils.calculateDateDifference(commission_timestamp, today_with_end_time, 'seconds') < 0) return null;

        const yesterday_date = moment(commission_datetime).tz(this.projectTimezone).subtract(1, 'days').format('YYYY-MM-DD');
        const yesterday_timestamp = moment(`${yesterday_date} ${this.START_TIME}`).valueOf();
        const tommorrow_date = moment(commission_datetime).tz(this.projectTimezone).add(1, 'days').format('YYYY-MM-DD');
        const tommorrow_timestamp = moment(`${tommorrow_date} ${this.END_TIME}`).valueOf();

        return this.checkDateInRange(commission_timestamp, yesterday_timestamp, commission_with_end_time)
            ? yesterday_date
            : this.checkDateInRange(commission_timestamp, commission_with_start_time, tommorrow_timestamp)
            ? commission_date
            : null;
    }

    checkDateInRange(date, start_date, end_date){
        return Utils.calculateDateDifference(start_date, date, 'seconds') >= 0 && Utils.calculateDateDifference(date, end_date, 'seconds') >= 0;
    }
}
